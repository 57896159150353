import jsPDF from "jspdf";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { columnsFromBackend } from './KanbanData';
import PalletCard from './PalletCard';
import AuthService from "../../Components/auth/AuthService";
import Message from '../../Components/common/Message';
import { FormButton } from '../../Components/UiElements/UiElements';
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import { makeStyles } from "@mui/styles";
import 'jspdf-autotable';

const useStyles = makeStyles((theme) => ({
  taskList: {
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    background: '#f3f3f3',
    width: '340px',
    borderRadius: '5px',
    padding: '15px 15px',
    marginRight: '10px',
    height:'300px',
    overflow: 'auto'
  },
  TaskColumnStyles: {
    margin: '8px',
    // display: flex;
    // direction: column;
    float:'left',
    width: '100%',
    minHeight: '40vh',
  },
  Title: {
    color: '#10957d',
    background: 'rgba(16, 149, 125, 0.15)',
    padding: '2px 10px',
    borderRadius: '5px',
    alignSelf: 'flex-start',
  },
  WeightParagraph: {
    margin: '0 200px 0 0'
  }
}));

const PalletBuilder = (props) => {
  var layoutDispatch = useLoadingDispatch();
  console.log('props11', props?.selectedRoute?.start_status);
  
  const [columns, setColumns] = useState(props.pallets);
  const [leftWeight, setLeftWeight] = useState(0);
  const [rightWeight, setRightWeight] = useState(0);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const Auth = new AuthService();
  const classes = useStyles();

  useEffect(() => {
    let lWeight = 0;
    let rWeight = 0;
    columns.map((c, idx) => {
      if (idx % 2 == 0) {
        lWeight += c.weight;
      }
      else {
        rWeight += c.weight;
      }
    });

    setLeftWeight(lWeight);
    setRightWeight(rWeight);
  }, [props.pallets]);

  const savePallets = () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const startStatus = props.selectedRoute.start_status > 0
    if(startStatus) {
      setType('error')
      setLoadMessage(`Can't save pallet builder while route is started ${str}`);
      return
    }

    const pallets = [];

    Object.entries(columns).map(([columnId, column], index) => {
      pallets.push(column)
    });

    let reqBody = {};
    reqBody.delivery_date = moment(props.deliveryDate).format('YYYY-MM-DD');
    reqBody.route_status_id = props.selectedRoute.id;
    reqBody.palletsArr = [];
    pallets.map((pallet) => {
      let reqObj = {};
      reqObj.title = pallet.title;
      reqObj.pallet_id = pallet.pallet_id;
      reqObj.weight = pallet.weight;
      reqObj.orders = [];
      if (pallet.items.length > 0) {

        pallet.items.map((order) => {
          let orderObj = {};
          orderObj.rank = order.rank;
          orderObj.internal_id = order.internalId;
          orderObj.inv_internal_id = order.invInternalId;
          orderObj.invoice_no = order.invoiceNo;
          orderObj.sale_order_no = order.saleOrderNo;
          orderObj.delivery_instructions = order.deliveryInstructions;
          orderObj.customer_name = order.customerName;
          orderObj.customer_project_id = order.customerProjectId;
          orderObj.customer_internal_id = order.customerInternalId;
          orderObj.order_weight = order.orderWeight;
          orderObj.ship_address1 = order.shipAddress1;
          orderObj.ship_zip = order.shipZip;
          orderObj.customer_opening_time = order.customerOpeningTime;
          orderObj.terms = order.terms;

          orderObj.items = [];
          if (order.items.length > 0) {
            order.items.map((item) => {
              orderObj.items.push({
                item_rank: item.itemRank,
                item_id: item.itemId,
                item_name: item.itemName,
                item_total_weight: item.itemTotalWeight,
                item_weight: item.itemWeight,
                quantity: item.quantity,
                unit_price: item.unitPrice,
                gross_amount_incl_tax: item.grossAmountIncludingTax,
                frozen_sheet_item: item.frozenSheetItem,
                cases_in_pallet: item.casesInPallet
              });
            })
          }
          reqObj.orders.push(orderObj);
        })
      }
      reqBody.palletsArr.push(reqObj)
    });

    setLoadMessage("Saving pallet builder ...");
    showLoading(layoutDispatch);
    Auth.fetch("/route/save-pallet-builder", {
      method: "POST",
      body: JSON.stringify({ reqBody }),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack === true) {
          setType("success");
          setLoadMessage(res.message);
          // setTimeout(() => {
          //   navigate("/routes");
          // }, 1000);
          // setLoading(false);
        } else {
          setType("error");
          setLoadMessage(res.message);
          // setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const printPalletSheet = () => {
    let pdf = new jsPDF('p', 'pt', 'A4');

    let col = ['Description', 'Shop Code', 'Shop Name', 'QTY'];
    var startrow = 25;
    let pageNo = 1;

    const totalPagesExp = "{total_pages_count_string}";

    const pallets = [];
    Object.entries(columns).map(([columnId, column], index) => {
      pallets.push(column)
    });

    pallets.map((pallet, idx) => {
      if (pallet.items.length > 0) {

        let palletNo = idx + 1;
        let rows = [];
        let pallet_qty = 0;
        for (var idx = pallet.items.length - 1; idx >= 0; idx--) {
          pallet.items[idx].items.map((item) => {
            if (item.itemId != 'Delivery Charge') {

              pallet_qty += Number(item.quantity);
              let tRow = [];
              tRow.push(item.itemName);
              tRow.push(pallet.items[idx].customerName);
              tRow.push(pallet.items[idx].customerProjectId);
              tRow.push(item.quantity);
              rows.push(tRow);
            }
          });
        }
        // pallet.items.map((order) => {
        //   order.items.map((item) => {
        //     if (item.itemId != 'Delivery Charge') {

        //       pallet_qty += Number(item.quantity);
        //       let tRow = [];
        //       tRow.push(item.itemName);
        //       tRow.push(order.customerName);
        //       tRow.push(order.customerProjectId);
        //       tRow.push(item.quantity);
        //       rows.push(tRow);
        //     }
        //   });
        // });


        const pageContent = (data) => {
          pdf.setFontSize(14);
          pdf.text(moment(props.deliveryDate).format('DD-MMMM-YYYY'), 415, startrow);
          pdf.setLineWidth(2.0);
          pdf.line(15, startrow + 12, 350, startrow + 12)
          pdf.setFontSize(12);
          pdf.text("PALLET QUANTITY", 25, startrow + 5);
          pdf.text("PALLET WEIGHT", 250, startrow + 5);
          pdf.setLineWidth(1.0);

          //PaLLET weight
          pdf.line(15, startrow + 30, 350, startrow + 30)
          pdf.setFontSize(50);
          pdf.text(props.selectedRoute.registration_number, 40, startrow + 110);
          pdf.line(15, startrow + 150, 350, startrow + 150)
          pdf.line(15, startrow + 290, 350, startrow + 290)
          pdf.line(15, startrow + 12, 15, startrow + 290)
          pdf.line(350, startrow + 12, 350, startrow + 290)
          var i;
          pdf.setFontSize(12);

          pdf.text("" + pallet_qty, 80, startrow + 25);
          pdf.text("" + pallet.weight.toFixed(2), 300, startrow + 25);
          pdf.setFontSize(18);
          if (palletNo == 1) {
            pdf.text("" + palletNo, 420, startrow + 65);
          }
          else if (palletNo == 2) {
            pdf.text("" + palletNo, 510, startrow + 65);
          }
          else if (palletNo == 3) {
            pdf.text("" + palletNo, 420, startrow + 95);
          }
          else if (palletNo == 4) {
            pdf.text("" + palletNo, 510, startrow + 95);
          }
          else if (palletNo == 5) {
            pdf.text("" + palletNo, 420, startrow + 125);
          }
          else if (palletNo == 6) {
            pdf.text("" + palletNo, 510, startrow + 125);
          }
          else if (palletNo == 7) {
            pdf.text("" + palletNo, 420, startrow + 155);
          }
          else if (palletNo == 8) {
            pdf.text("" + palletNo, 510, startrow + 155);
          }
          else if (palletNo == 9) {
            pdf.text("" + palletNo, 420, startrow + 185);
          }
          else if (palletNo == 10) {
            pdf.text("" + palletNo, 510, startrow + 185);
          }
          else if (palletNo == 11) {
            pdf.text("" + palletNo, 420, startrow + 215);
          }
          else if (palletNo == 12) {
            pdf.text("" + palletNo, 510, startrow + 215);
          }
          else if (palletNo == 13) {
            pdf.text("" + palletNo, 420, startrow + 245);
          }
          else if (palletNo == 14) {
            pdf.text("" + palletNo, 510, startrow + 245);
          }

          //Inside Pallet Weight Box
          pdf.line(28, startrow + 190, 85, startrow + 190)
          pdf.line(28, startrow + 280, 85, startrow + 280)
          pdf.line(28, startrow + 190, 28, startrow + 280)
          pdf.line(85, startrow + 190, 85, startrow + 280)
          pdf.setFontSize(9);
          var row = 1;
          pallet.items.forEach(obj => {
            pdf.text("" + obj.rank, 90, startrow + 200 + (15 * row));
            pdf.text(" - " + obj.customerProjectId + " (" + obj.customerName + ")", 105, startrow + 200 + (15 * row));
            row = row + 1;
          });


          //Driver
          pdf.setLineWidth(2.0);
          pdf.setFontSize(12);
          pdf.text("DRIVER - (" + props.selectedRoute.driver_name + ")", 400, startrow + 20);
          pdf.line(370, startrow + 30, 575, startrow + 30)
          pdf.line(370, startrow + 270, 575, startrow + 270)
          pdf.line(370, startrow + 29, 370, startrow + 272)
          pdf.line(575, startrow + 29, 575, startrow + 272)
          pdf.text("TAILIFT", 430, startrow + 290);
          //Inside Driver
          pdf.setLineWidth(1.0);
          pdf.setFontSize(20);
          // pdf.text("2", 526, startrow+91);
          pdf.line(385, startrow + 50, 385, startrow + 70)
          pdf.line(385, startrow + 80, 385, startrow + 100)
          pdf.line(385, startrow + 110, 385, startrow + 130)
          pdf.line(385, startrow + 140, 385, startrow + 160)
          pdf.line(385, startrow + 170, 385, startrow + 190)
          pdf.line(385, startrow + 200, 385, startrow + 220)
          pdf.line(385, startrow + 230, 385, startrow + 250)

          pdf.line(465, startrow + 50, 465, startrow + 70)
          pdf.line(465, startrow + 80, 465, startrow + 100)
          pdf.line(465, startrow + 110, 465, startrow + 130)
          pdf.line(465, startrow + 140, 465, startrow + 160)
          pdf.line(465, startrow + 170, 465, startrow + 190)
          pdf.line(465, startrow + 200, 465, startrow + 220)
          pdf.line(465, startrow + 230, 465, startrow + 250)

          pdf.line(475, startrow + 50, 475, startrow + 70)
          pdf.line(475, startrow + 80, 475, startrow + 100)
          pdf.line(475, startrow + 110, 475, startrow + 130)
          pdf.line(475, startrow + 140, 475, startrow + 160)
          pdf.line(475, startrow + 170, 475, startrow + 190)
          pdf.line(475, startrow + 200, 475, startrow + 220)
          pdf.line(475, startrow + 230, 475, startrow + 250)

          pdf.line(560, startrow + 50, 560, startrow + 70)
          pdf.line(560, startrow + 80, 560, startrow + 100)
          pdf.line(560, startrow + 110, 560, startrow + 130)
          pdf.line(560, startrow + 140, 560, startrow + 160)
          pdf.line(560, startrow + 170, 560, startrow + 190)
          pdf.line(560, startrow + 200, 560, startrow + 220)
          pdf.line(560, startrow + 230, 560, startrow + 250)

          pdf.line(385, startrow + 50, 465, startrow + 50)
          pdf.line(475, startrow + 50, 560, startrow + 50)
          pdf.line(385, startrow + 70, 465, startrow + 70)
          pdf.line(475, startrow + 70, 560, startrow + 70)


          pdf.line(385, startrow + 80, 465, startrow + 80)
          pdf.line(475, startrow + 80, 560, startrow + 80)
          pdf.line(385, startrow + 100, 465, startrow + 100)
          pdf.line(475, startrow + 100, 560, startrow + 100)


          pdf.line(385, startrow + 110, 465, startrow + 110)
          pdf.line(475, startrow + 110, 560, startrow + 110)
          pdf.line(385, startrow + 130, 465, startrow + 130)
          pdf.line(475, startrow + 130, 560, startrow + 130)

          pdf.line(385, startrow + 140, 465, startrow + 140)
          pdf.line(475, startrow + 140, 560, startrow + 140)
          pdf.line(385, startrow + 160, 465, startrow + 160)
          pdf.line(475, startrow + 160, 560, startrow + 160)

          pdf.line(385, startrow + 170, 465, startrow + 170)
          pdf.line(475, startrow + 170, 560, startrow + 170)
          pdf.line(385, startrow + 190, 465, startrow + 190)
          pdf.line(475, startrow + 190, 560, startrow + 190)

          pdf.line(385, startrow + 200, 465, startrow + 200)
          pdf.line(475, startrow + 200, 560, startrow + 200)
          pdf.line(385, startrow + 220, 465, startrow + 220)
          pdf.line(475, startrow + 220, 560, startrow + 220)

          pdf.line(385, startrow + 230, 465, startrow + 230)
          pdf.line(475, startrow + 230, 560, startrow + 230)
          pdf.line(385, startrow + 250, 465, startrow + 250)
          pdf.line(475, startrow + 250, 560, startrow + 250)

          // FOOTER
          var str = "Page " + pageNo;// data.pageCount;
          // Total page number plugin only available in jspdf v1.0+
          if (typeof pdf.putTotalPages === 'function') {
            str = str;// + " of " + totalPagesExp;
          }
          pdf.setFontSize(10);
          var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
          pdf.text(str, data.settings.margin.left, pageHeight - 10); // showing current page number

          pageNo += 1;
        }

        pdf.autoTable(col, rows,
          {

            didDrawPage: pageContent,
            theme: "grid",
            margin: {
              top: startrow + 310,
              left: 15
            },
            styles: {
              fontSize: 8, valign: 'middle',
              halign: 'center',
              align: 'center'
            },
            columnStyles: {
              0: { cellWidth: 230, valign: 'left', halign: 'left', align: 'left' },
              1: { cellWidth: 60 },
              2: { cellWidth: 230, valign: 'left', halign: 'left', align: 'left' },
              3: { cellWidth: 45 },
            }
          });

        //for adding total number of pages // i.e 10 etc
        if (typeof pdf.putTotalPages === 'function') {
          pdf.putTotalPages(totalPagesExp);
        }
        pdf.addPage();

      }
    });
    pdf.save(props.selectedRoute.code + "-" + moment(props.deliveryDate).format('DD-MM-YYYY') + '-' + props.selectedRoute.registration_number.replace(' ', '-') + '.pdf');

  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];


      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      sourceColumn.weight -= Number(sourceItems[source.index].orderWeight);
      destColumn.weight += Number(sourceItems[source.index].orderWeight);

      let srcColmn = Number(source.droppableId);
      let destColmn = Number(destination.droppableId);
      if (srcColmn % 2 != destColmn % 2) {
        let lWeight = leftWeight;
        let rWeight = rightWeight;
        if (srcColmn % 2 == 0) {
          lWeight -= sourceItems[source.index].orderWeight;
          rWeight += sourceItems[source.index].orderWeight;
        }
        else {
          lWeight += sourceItems[source.index].orderWeight;
          rWeight -= sourceItems[source.index].orderWeight;
        }

        setLeftWeight(lWeight);
        setRightWeight(rWeight);
      }

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });

    }
  };
  return (
    <>
      <div style={{ width: "740px" }}>
        <p className={classes.WeightParagraph}>Left Weight: <b>{leftWeight.toFixed(2)} KG</b>, Right Weight: <b>{rightWeight.toFixed(2)} KG</b></p>
        <p className={classes.WeightParagraph}>Difference: <span style={{ color: (Math.abs(leftWeight - rightWeight) >= 400 ? "red" : "blue") }}> <b>{Math.abs(leftWeight - rightWeight).toFixed(2)} KG</b></span> </p>
        <div style={{ float: "right", marginTop: "-50px" }}>
          {props.showPrint && <FormButton type="save" onClick={printPalletSheet}>Print</FormButton>}
          <FormButton type="save" onClick={savePallets}>Save</FormButton>
          <FormButton type="reset" onClick={() => { props.loadRoutesOrder(props.selectedRoute.id, true) }}>Reset</FormButton>
          {/* <Button variant="contained" onClick={savePallets} style={{ marginRight: "5px" }}>Save</Button>
          <Button onClick={() => { props.loadRoutesOrder(props.selectedRoute.id) }}>Reset</Button> */}
          {/* <Button variant="contained" onClick={printPallets}>Print Reports</Button> */}
        </div>
      </div>
      <div style={{ width: "750px" }}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          <div>
            <div className={classes.TaskColumnStyles}>
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <div style={{ float: "left", margin: "10px" }}>
                    <Droppable key={columnId} droppableId={columnId}>
                      {(provided, snapshot) => (

                        <div className={classes.taskList}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <span className={classes.Title}>{column.title}</span>
                          <p style={{ float: "right" }}>Total Weight: <span style={{ color: (column.weight > 650 ? "red" : "black") }}>{Math.abs(column.weight).toFixed(2)} KG</span></p>
                          {column.items.map((item, index) => (
                            <PalletCard key={item} item={item} index={index} />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>

                  </div>
                );
              })}
            </div>

          </div>
        </DragDropContext>

      </div>
      <Message type={type} msg={loadMessage} />
    </>
  );
};

export default PalletBuilder;
